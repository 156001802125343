<template>
  <div class="map-picker">
    <div v-if="loading" class="loading-overlay">
      <van-loading type="spinner" color="#1989fa" />
    </div>
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <span>案件地图</span>
      </template>
    </van-nav-bar>
    <div class="map-container">
      <div id="mapContainer" style="width: 100%; height: 100%;"></div>
    </div>
    <div class="changeBtn" @click="changeMap">切换到日历</div>
    <van-popup position="bottom" v-model="showPopups">
      <!-- :style="{ height: '35%' }" -->
      <div class="contents">
        <van-field
          label="基本信息"
          :value="
            `${currentItem.customerName || '-'}-${currentItem.vehiclePlateNo ||
              '-'}`
          "
          readonly
        />
        <van-field
          label="业务编号"
          :value="currentItem.applyBizNo | dash"
          readonly
        />
        <van-field
          label="家访时间"
          :value="currentItem.homeVisitDate | dash"
          readonly
        />
        <van-field
          :label="currentItem.typeStr"
          :value="currentItem.address | dash"
          type="textarea"
          readonly
        />
      </div>
      <div class="btns flex-between">
        <van-button
          color="#3C86FF"
          block
          type="default"
          plain
          class="sign-btn"
          @click="showPopups = false"
          >取消</van-button
        >
        <van-button
          color="#3C86FF"
          v-if="currentItem.homeNo"
          block
          type="info"
          @click="goDetails()"
          class="sign-btn"
          >查看详情</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import Api from "@/api/user";
import { Toast } from "vant";
import popupFormMixin from "@/mixins/popupFormMixin";

export default {
  mixins: [popupFormMixin],
  data() {
    return {
      currentIndex: 0,
      address: "",
      map: null,
      keywords: "",
      marker: null,
      selectedLocation: null,
      loading: false,
      markerList: [], // 服务端获取到的markerList
      currentViewMarkerList: [], // 当前视口下的markerList
      markers: [], // 绘制到地图上的markerList
      clustererObject: {}, // 点聚合对象
      showPopups: false,
      currentItem: {},
      userBaseInfo: {},
    };
  },
  async mounted() {
    this.$nextTick(() => {
      this.showMap();
    });
  },
  methods: {
    changeMap() {
      // 切换到日历但不加入历史轴
      this.$router.replace("/calendar");
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    /** 地图相关 start **/
    showMap() {
      this.initMap();
    },
    initMap() {
      if (this.map) return;
      // 确保天地图 JS API 已加载
      if (!window.T) {
        console.error("天地图 JS API 未加载");
        return;
      }
      var pixelRatio = window.devicePixelRatio || 1;
      this.map = new window.T.Map("mapContainer", {
        projection: 'EPSG:4326',
        zoom: 10,
        minZoom: 1,
        maxZoom: 18,
        tileSize: 512,
        zoomOffset: -1, // 调整缩放偏移
        //1.设置缩放级别为整数
        constrainResolution: true,
        //2.关闭无级缩放地图
        smoothResolutionConstraint: false,
        // 使用高分辨率瓦片
        tileUrl: 'http://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=862f1ed5b50768c2fe67f164211dae9d',
        subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
        dpr: pixelRatio > 1 ? 2 : 1, // 设置 DPR
      });
      // 尝试获取用户位置信息
      const zoom = 11;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lng = position.coords.longitude;
            const lat = position.coords.latitude;
            // 使用用户位置进行中心和缩放
            this.map.centerAndZoom(new window.T.LngLat(lng, lat), zoom);

            // 初始化所有标点
            this.skyMapLocationsList();
            this.addZoomListener();
          },
          (error) => {
            console.error("获取用户位置失败:", error);
            // 默认位置
            this.map.centerAndZoom(new window.T.LngLat(116.404, 39.915), zoom);

            // 初始化所有标点
            this.skyMapLocationsList();
            this.addZoomListener();
          }
        );
      } else {
        console.error("浏览器不支持地理位置服务");
        // 默认位置
        this.map.centerAndZoom(new window.T.LngLat(116.404, 39.915), zoom);
      }
      this.map.addEventListener("click", (e) => {
        const lnglat = e.lnglat;
        // this.updateMarker(lnglat);
        this.selectedLocation = lnglat;
      });
    },

    updateMarker(lnglat) {
      if (this.marker) {
        this.map.removeOverLay(this.marker);
      }
      this.marker = new window.T.Marker(lnglat);
      this.map.addOverLay(this.marker);
      // 将视图移动到中心位置
      this.map.panTo(lnglat);
    },

    // 关于地图打点，有时需要打几百 上千个点位，会出现地图加载卡顿的问题，这时我们就需要用点聚合的方式 进行加载
    handleViewPorts() {
      //如果重复调用handleViewPorts 需要clearOverLays 清除之前的点位信息
      const T = window.T;
      if (this.map) {
        this.map.clearOverLays();
      }
      this.markers = [];
      for (var i = 0; i < this.markerList.length; i++) {
        let item = this.markerList[i];
        let marker = this.addMarker(item);
        this.markers.push(marker);
      }

      // 加载点聚合是下面这段代码，map就是天地图对象
      this.clustererObject = new T.MarkerClusterer(this.map, {
        markers: this.markers,
      });
      // 给聚合点添加点击事件
      this.addClusterClickEvent();

      //禁用原有聚合点的点击事件
      // for (let i = 0; i < Object.keys(this.clustererObject).length; i++) {
      //   if (
      //     this.clustererObject[Object.keys(this.clustererObject)[i]]
      //       .clusterclick
      //   ) {
      //     this.clustererObject[
      //       Object.keys(this.clustererObject)[i]
      //     ].clusterclick.shift();
      //   }
      // }
    },
    addClusterClickEvent() {
      const T = window.T;
      this.clustererObject.addEventListener("clusterclick", (e) => {
        //聚合点的数据信息
        var markerDataArr = [];
        if (!e.target.selfData) {
          //聚合点点击
          e.target.options.markers.forEach((item) => {
            //标记点与当前聚合点 距离小于60时 表示在此聚合内
            var cupx = e.layerPoint;
            var lngLat = T.LngLat(
              item.options.icon.options.uniqueCode.lon,
              item.options.icon.options.uniqueCode.lat
            );
            var px = this.map.lngLatToLayerPoint(lngLat);
            if (this.getPosLen(cupx, px) < 60) {
              markerDataArr.push(item);
            }
          });
        }
        let { lon, lat } = markerDataArr[0].options.icon.options.uniqueCode;
        // 以当前点为中心将层级缩小到13级
        this.map.centerAndZoom(new window.T.LngLat(lon, lat), 14);
      });
    },
    addMarkerLabel(item) {
      const T = window.T;
      let text = "";
      let type = item.typeStr.substring(0, 2);
      text += type;
      if (item.customerName) {
        text += " " + item.customerName;
      }
      if (item.vehiclePlateNo) {
        text += `(${item.vehiclePlateNo})`;
      }
      let label = new T.Label({
        text: text || "标记点", // 假设item有name属性，如果没有则显示"标记点"
        position: new T.LngLat(item.lon, item.lat),
        offset: new T.Point(0, -10), // 调整标签位置，使其显示在图标上方
        uniqueCode: item,
      });
      label.setFontColor("#0061ff");
      label.setBackgroundColor("transparent");
      label.setBorderLine("none");
      label.setBorderColor("transparent");

      // 为单个点添加点击事件;
      const addClickEventToLabel = (label) => {
        label.addEventListener("click", (e) => {
          const markerData = e.target.options.uniqueCode;
          this.markerClick(markerData);
        });
      };

      addClickEventToLabel(label);

      this.map.addOverLay(label);
    },
    // 获取像素间距离
    getPosLen(sdot, edot) {
      //获取2点距离
      return parseInt(
        Math.sqrt(
          Math.pow(Math.abs(sdot.x - edot.x), 2) +
            Math.pow(Math.abs(sdot.y - edot.y), 2)
        )
      );
    },

    // 监听缩放等级，根据当前等级将当前区域下的点进行缩放
    handleZoomChange() {
      const currentZoom = this.map.getZoom();
      const threshold = 13; // 设置一个阈值，可以根据需要调整
      // 在this.markerList中找出当前视口下的所有点，超出当前视口的点抛弃
      // eslint-disable-next-line
      // console.log('this.markerList :>> ', this.markerList);
      // const markers = this.markerList.filter((item) => {
      //   const lnglat = new window.T.LngLat(item.lon, item.lat);
      //   const pixel = this.map.lngLatToContainerPoint(lnglat);
      //   console.log('pixel :>> ', pixel);
      //   const inView =
      //     pixel.x > 0 &&
      //     pixel.x < this.map.width &&
      //     pixel.y > 0 &&
      //     pixel.y < this.map.height;
      //   return inView;
      // });
      // 重新设置markerList
      // this.currentViewMarkerList = markers;
      // console.log('this.currentViewMarkerList :>> ', this.currentViewMarkerList);
      // 阈值判断，当缩放等级大于阈值时，进行点聚合，否则进行单个点的展示
      if (currentZoom > threshold) {
        // 添加单个标记点
        this.map.clearOverLays();
        this.markers.forEach((marker) => {
          // this.map.addOverLay(marker);
          let item = marker.options.icon.options.uniqueCode;
          this.addMarkerLabel(item);
        });
      } else {
        this.handleViewPorts();
      }
    },

    // 在地图初始化后添加缩放监听
    addZoomListener() {
      this.map.addEventListener("zoomend", this.handleZoomChange);
    },

    //单个marker
    addMarker(item) {
      // item打点位经纬度根据自己的实际情况修改
      const T = window.T;
      //uniqueCode 可以给icon点击时添加自定义参数

      // 为单个点添加点击事件;
      const addClickEventToMarker = (marker) => {
        marker.addEventListener("click", (e) => {
          const markerData = e.target.options.icon.options.uniqueCode;
          this.markerClick(markerData);
        });
      };

      let icon = new T.Icon({
        iconUrl: require("../../assets/images/location.png"),
        iconSize: new T.Point(32, 32),
        iconAnchor: new T.Point(10, 25),
        uniqueCode: item,
      });
      if (item.lon == "" || !item.lon || item.lat == "" || !item.lat) return;
      //向地图上添加自定义标注
      let marker = new T.Marker(new T.LngLat(item.lon, item.lat), {
        icon: icon,
      });
      addClickEventToMarker(marker);
      return marker;
    },

    // 点击查询
    async markerClick(item) {
      try {
        this.loading = true;
        let res = await Api.getProcessHomeByProjectcode(item.projectCode);
        let obj = res.data || {};
        this.currentItem = {
          ...obj,
          typeStr: item.typeStr,
          address: item.address,
        };
        this.showPopups = true;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        Toast.fail(error.message || error);
      }
    },

    skyMapLocationsList() {
      let { resOperatorDTO = {} } = JSON.parse(
        localStorage.getItem("userAccount") || "{}"
      );
      let { orgNo = "-", isAdmin, operatorId } = resOperatorDTO;
      Api.skyMapLocationsList({
        statusEq: "PROCESSING",
        orgNoEq: orgNo,
        ...(isAdmin ? {} : { candidateEq: operatorId }),
      }).then((res) => {
        this.markerList = res.data;
        this.currentViewMarkerList = res.data;
        this.handleViewPorts();
      });
    },
    isShowBtn() {
      if (
        this.currentItem.candidate &&
        this.currentItem.candidate.includes(
          this.userBaseInfo?.resOperatorDTO?.operatorId
        )
      ) {
        return true;
      }
      return false;
    },
    goDetails() {
      this.$router.push({
        name: "CaseDetail",
        params: {
          homeNo: this.currentItem.homeNo,
        },
        query: {
          bizNo: this.currentItem.applyBizNo,
          readonly: !this.isShowBtn(),
        },
      });
    },
    /** 地图相关 end **/
  },
};
</script>

<style lang="less" scoped>
.map-picker {
  height: 100vh;
  width: 100%;
}

.map-container {
  position: relative;
  height: 100%;
}

.changeBtn {
  position: fixed;
  bottom: 6rem;
  right: 1.25rem;
  width: 3.75rem;
  height: 3.75rem;
  line-height: 1.3;
  text-align: center;
  border-radius: 50%;
  background-color: #3c86ff;
  color: #fff;
  font-size: 0.75rem;
  cursor: pointer;
  z-index: 1999;
  padding: 0px 9px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
}
.btns {
  justify-content: space-around;
  margin-bottom: 10px;
  button {
    width: 40%;
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
